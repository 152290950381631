import * as React from "react"
import Seo from "../components/seo"
import Contact from "../components/sections/contact/Contact"
// import NewsLetter from "../components/sections/home/newsLetter/NewsLetter"
import { Helmet } from "react-helmet"

const ContactPage = () => (
  <>
    <Seo title="Contact" />
    <Helmet bodyAttributes={{ class: "header-class" }} />
    <Contact/>
    {/* <NewsLetter/> */}
  </>
)

export default ContactPage
